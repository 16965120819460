



























































import { Money } from "v-money";
import { AppEventBus, AppEventBusEvents } from "@/helpers/event_bus";
import Drawdown, { DrawdownAction } from "@/models/drawdown";
import { Component, Prop, Vue } from "vue-property-decorator";
import * as _ from "lodash";

@Component({ components: { Money } })
export default class DrawdownFormComponent extends Vue {
  drawdown: Drawdown = {
    drawdownID: "",
    amount: 0,
    monthsAfterInitial: 0,
    formRID: "",
  };
  moneyFormatForComponent: any = {
    decimal: ".",
    thousands: ",",
    prefix: "E ",
    suffix: "",
    precision: 2,
    masked: true,
  };

  @Prop({ default: "index" })
  index!: number;
  @Prop({ default: true })
  readonly!: number;
  @Prop({ default: DrawdownAction.Update })
  drawdownAction!: DrawdownAction;

  @Prop()
  item!: Drawdown;

  mounted() {
    if (this.item !== undefined) {
      console.log(this.item);
      this.drawdown = _.clone(this.item);
    }
  }
  get readonlyTime() {
    return this.drawdownAction === DrawdownAction.View;
  }
  updateValue(key: any, value: any) {
    if (this.drawdownAction === DrawdownAction.Update) {
      AppEventBus.$emit(AppEventBusEvents.drawdownEvent, {
        ...this.drawdown,
        [key]: value,
        index: this.index,
      });
      return;
    }
    AppEventBus.$emit(AppEventBusEvents.drawdownEvent, {
      ...this.drawdown,
      [key]: value,
      index: this.index,
    });
  }
}
